<template>
  <div>
    <van-tabs v-model="form.catId" @click="onClick" :class="$store.state.style">
      <van-tab
        :title="item.text"
        v-for="item in tabs"
        :key="item.index"
        :name="item.value"
      >
      </van-tab>
    </van-tabs>
    <div :class="['main', $store.state.style]">
      <Card
        v-for="item in list"
        :key="item.index"
        :data="item"
        @click.native="jump(item)"
      />
      <div class="more" v-show="!loading" @click="more">加载更多...</div>
      <van-loading type="spinner" v-show="loading">加载中...</van-loading>
      <div class="record" @click="subRecord">{{ record }}</div>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs, Toast, Loading } from 'vant'
import { getNewsList } from '@/api'
import therecord from '@/utils/getForTherecord'
import tabs from '@/utils/tabs'
import Card from '@/components/Card'
export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Loading.name]: Loading,
    Card
  },
  data() {
    return {
      tabs,
      form: {
        catId: 8,
        page: 1,
        size: 20
      },
      list: [],
      loading: false
    }
  },
  computed: {
    // 根据域名显示不同备案号
    record() {
      const href = window.location.host
      let record = ''
      therecord.forEach(el => {
        if (href.indexOf(el.url) !== -1) {
          record = el.val
          document.title = el.title
        }
      })
      return record
    }
  },
  created() {
    this.init()
  },
  methods: {
    // 页面初始化、加载更多数据
    init() {
      this.loading = true
      getNewsList(this.form).then(res => {
        if (res.code === 200) {
          this.list = this.list.concat(res.data.listInfo)
        } else {
          Toast.fail(res.message)
        }
        this.loading = false
      })
    },
    // 加载更多
    more() {
      this.form.page++
      this.init()
    },
    // tabs跳转
    onClick() {
      this.list = []
      this.form.page = 1
      this.init()
    },
    // 选择新闻
    jump(val) {
      this.$router.push(`/new/${val.id}`)
    },
    // 跳转至工信部官网
    subRecord() {
      window.location.href = 'https://beian.miit.gov.cn/'
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs {
  height: 43px;
  box-shadow: 0 1px 5px #888888;
  .van-tabs__line {
    background-color: #409eff;
  }
}
/deep/ .van-tabs.styleB {
  .van-tabs__nav {
    background-color: #c23a2b;
  }
  .van-tab {
    color: #fff;
  }
  .van-tab--active {
    background-color: #e85346;
  }
  .van-tabs__line {
    background-color: transparent;
  }
}
/deep/ .van-tabs.styleC {
  .van-tabs__nav {
    background-color: #17161b;
  }
  .van-tab {
    color: #fff;
  }
  .van-tabs__line {
    background-color: #ee0a24;
  }
}
.main {
  padding: 0 8px;
  box-sizing: border-box;
  height: calc(100vh - 43px);
  overflow-y: auto;
  .more {
    margin-top: 10px;
    text-align: center;
    color: #939090;
    font-size: 14px;
  }
  .van-loading {
    margin-top: 10px;
    text-align: center;
  }
  .record {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
  }
}
.main.styleC {
  background-color: #f6f6f6;
}
</style>
