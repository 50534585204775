export default [
  {
    url: 'localhost:8080',
    val: '皖ICP备2021011844号-1:测试',
    title: '一点生活趣事',
    theme: ''
  },
  {
    url: 'localhost:8081',
    val: '皖ICP备2021011844号-1:测试',
    title: '一点生活趣事',
    theme: ''
  },
  {
    url: 'zylife.ren',
    val: '皖ICP备2021011844号-1',
    title: '乐活新闻',
    theme: 'A'
  },
  {
    url: 'hehestory.cn',
    val: '皖ICP备2021001482号-1',
    title: '赫赫资讯',
    theme: 'A'
  },
  {
    url: 'localfocus.cn',
    val: '皖ICP备20006649号-2',
    title: '一点生活趣事',
    theme: 'A'
  },
  {
    url: 'funthings.cn',
    val: '皖ICP备20006649号-4',
    title: '一点生活趣事',
    theme: 'A'
  },
  {
    url: 'shangjinssp.com',
    val: '皖ICP备20006649号-1',
    // title: '一点生活趣事',
    title: '个人信息管理',
    theme: 'A'
  },
  {
    url: 'hotnewx.com',
    val: '皖ICP备20006649号-3',
    title: '一点生活趣事',
    theme: 'A'
  },
  {
    url: 'shanghaierma.cn',
    val: '沪ICP备2022013236号-2',
    title: '贰麻资讯',
    theme: ''
  },
  {
    url: 'qukanzixun.cn',
    val: '沪ICP备2022013236号-1',
    title: '趣看资讯',
    theme: 'A'
  },
  {
    url: 'zytop.ren',
    val: '皖ICP备2021011844号-2',
    title: 'top资讯',
    theme: 'A'
  },
  {
    url: 'tiantiankuaile.cn',
    val: '皖ICP备2021001482号-3',
    title: '天天快看',
    theme: 'A'
  },
  {
    url: 'kaixinkankan.cn',
    val: '沪ICP备2022013236号-3',
    title: '开心资讯',
    theme: 'A'
  },
  {
    url: 'helloworldtree.cn',
    val: '皖lCP备2022010101号',
    title: '希望的每一日',
    theme: 'C'
  },
  {
    url: 'xinwenkandian.cn',
    val: '沪ICP备2022013236号-4',
    title: '新闻看点',
    theme: 'C'
  },
  {
    url: 'zyqifei.cn',
    val: '沪ICP备2022021090号-1',
    title: '每天开心看看',
    theme: 'C'
  },
  {
    url: 'wishday.cn',
    val: '沪ICP备2022021090号-2',
    title: '正能量的一天',
    theme: 'C'
  },
  {
    url: 'wisheveryoneday.cn',
    val: '皖ICP备2022011008号-1',
    title: '壹天资讯',
    theme: 'C'
  },
  {
    url: 'zylaoniubile.cn',
    val: '粤ICP备2022089301号-1',
    title: '日常每天',
    theme: 'A'
  },
  {
    url: 'qukanmeitu.cn',
    val: '皖ICP备2021001482号-4',
    title: '趣看美图',
    theme: 'A'
  },
  {
    url: 'icoofe.cn',
    val: '皖ICP备2022010955号-1',
    title: '休闲时光',
    theme: 'C'
  },
  {
    url: 'huotangzhi.cn',
    val: '沪ICP备2022021090号-3',
    title: '芝麻趣生活',
    theme: 'C'
  }
]
